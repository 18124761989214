"use client";

import { Button } from "@/components/ui/button";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible";
import { cn } from "@/lib/utils";
import * as React from "react";
import { motion, AnimatePresence } from 'framer-motion';

interface CodeBlockProps extends React.HTMLAttributes<HTMLDivElement> {
  expandButtonTitle?: string;
}

export function CodeBlockWrapper({
  expandButtonTitle = "View Code",
  className,
  children,
  ...props
}: CodeBlockProps) {
  const [isOpened, setIsOpened] = React.useState(false);




  // 动画配置
  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
    exit: { opacity: 0, y: -20, transition: { duration: 0.3 } }
  };



  return (
    <motion.div
      initial="hidden"
      animate="visible"
      exit="exit"
      variants={containerVariants}
    >
      <Collapsible open={isOpened} onOpenChange={setIsOpened}>
        <div className={cn("relative overflow-hidden", className)} {...props}>
          <CollapsibleContent
            forceMount
            className={cn("overflow-hidden", !isOpened && "max-h-72")}
          >


            <motion.div
              initial={{ maxHeight: 0, opacity: 0, scale: 0.98 }}
              animate={
                isOpened
                  ? { maxHeight: 650, opacity: 1, scale: 1 }
                  : { maxHeight: 300, opacity: 0.7, scale: 0.98 }
              }
              transition={{ duration: 0.7, ease: [0.04, 0.62, 0.23, 0.98] }}
              className={cn(
                "[&_pre]:my-0 [&_pre]:max-h-[650px] [&_pre]:pb-[100px]",
                !isOpened ? "[&_pre]:overflow-hidden" : "[&_pre]:overflow-auto]",
              )}
            >
              {children}
            </motion.div>


          </CollapsibleContent>

          <div
            className={cn(
              "absolute flex items-center justify-center bg-gradient-to-b from-background/10 to-background to-90% p-2",
              isOpened ? "inset-x-0 bottom-0 h-12 from-gray-900/30" : "inset-0 ",
            )}
          >
            <CollapsibleTrigger asChild>
              <Button variant="secondary" className="mb-8 h-8 text-xs">
                {isOpened ? "Collapse" : expandButtonTitle}
              </Button>
            </CollapsibleTrigger>
          </div>
        </div>
      </Collapsible>
    </motion.div>
  );
}