"use client";
import ComponentWrapper from "@/components/wrapper-install";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { cn } from "@/lib/utils";
import * as React from "react";
import { Button } from "@/components/ui/button";
import { CopyButton } from "@/components/install-copy-button";


interface InstallCodeProps extends React.HTMLAttributes<HTMLDivElement> {
  name: string;
  align?: "center" | "start" | "end";
}

export function InstallCode({
                              name,
                              className,
                              align = "center",
                              ...props
                            }: InstallCodeProps) {
  const npmCommand = `npx tofu-ui-cli@latest add ${name}`;
  const pnpmCommand = `pnpm dlx tofu-ui-cli@latest add ${name}`;
  const yarnCommand = `yarn dlx tofu-ui-cli@latest add ${name}`;

  return (
    <div className={cn("relative my-4 flex flex-col space-y-2 lg:max-w-[120ch]", className)} {...props}>
      <Tabs defaultValue="npm" className="relative w-full">
        <TabsList className="w-full justify-start rounded-none bg-transparent p-0 ">
          <TabsTrigger value="npm" className="{tabButton}">npm</TabsTrigger>
          <TabsTrigger value="pnpm" className="{tabButton}">pnpm</TabsTrigger>
          <TabsTrigger value="yarn" className="{tabButton}">yarn</TabsTrigger>
        </TabsList>
        <TabsContent value="npm" className="{tabContent}">
          <ComponentWrapper>
            <div className="flex justify-between items-center text-xl">
              <p>{npmCommand}</p>
              <CopyButton className="ml-2" value={npmCommand} />
            </div>
          </ComponentWrapper>
        </TabsContent>
        <TabsContent value="pnpm" className="{tabContent}">
          <ComponentWrapper>
            <div className="flex justify-between items-center text-xl">
              <span>{pnpmCommand}</span>
              <CopyButton className="ml-2" value={pnpmCommand} />
            </div>
          </ComponentWrapper>
        </TabsContent>
        <TabsContent value="yarn" className="{tabContent}">
          <ComponentWrapper>
            <div className="flex justify-between items-center text-xl">
              <span>{yarnCommand}</span>
              <CopyButton className="ml-2" value={yarnCommand} />
            </div>
          </ComponentWrapper>
        </TabsContent>
      </Tabs>
    </div>
  );
}

// Assume these styles are defined in your CSS
const tabButton = "relative h-9 rounded-none bg-transparent px-4 pb-3 pt-2 font-semibold text-muted-foreground shadow-none transition-all  data-[state=active]:text-foreground data-[state=active]:shadow-none";
const tabContent = "relative rounded-md p-4 flex justify-between items-center";
