import { Registry } from "@/registry/schema";
import * as React from "react";

const ui: Registry = {
  "badge": {
    name: "badge",
    type: "components:ui",
    files: ["registry/components/tofu/badge.tsx"],
  },
  "avatar": {
    name: "avatar",
    type: "components:ui",
    files: ["registry/components/tofu/avatar.tsx"],
  },
  "button": {
    name: "button",
    type: "components:ui",
    files: ["registry/components/tofu/button.tsx"],
  },
  "Alert": {
    name: "Alert",
    type: "components:ui",
    files: ["registry/components/tofu/alert.tsx"],
  },
  "big-post": {
    name: "bigpost",
    type: "components:ui",
    files: ["registry/components/tofu/bigpost.tsx"],
  },
  "circular-progress": {
    name: "circular-progress",
    type: "components:ui",
    files: ["registry/components/tofu/CircularProgress.tsx"],
  },
  "data-table": {
    name: "data-table",
    type: "components:ui",
    files: ["registry/components/tofu/data-table.tsx"],
  },
  "dropdown-menu": {
    name: "dropdown-menu",
    type: "components:ui",
    files: ["registry/components/tofu/dropdown-menu.tsx"],
  },
  "footer": {
    name: "footer",
    type: "components:ui",
    files: ["registry/components/tofu/footer.tsx"],
  },
  "header": {
    name: "header",
    type: "components:ui",
    files: ["registry/components/tofu/header.tsx"],
  },

};

const example: Registry = {
  "badge-demo": {
    name: "badge-demo",
    type: "components:example",
    files: ["registry/components/example/badge/Badge-demo.tsx"],
    component: React.lazy(
      () => import("@/registry/components/example/badge/Badge-demo")
    ),
  },
  "badge-demo-en": {
    name: "badge-demo-en",
    type: "components:example",
    files: ["registry/components/example/en/badge/Badge-demo.tsx"],
    component: React.lazy(
      () => import("@/registry/components/example/en/badge/Badge-demo")
    ),
  },

  "button-demo": {
    name: "button-demo",
    type: "components:example",
    files: ["registry/components/example/button/Button-demo.tsx"],
    component: React.lazy(
      () => import("@/registry/components/example/button/Button-demo")
    ),
  },
  "button-demo-en": {
    name: "button-demo-en",
    type: "components:example",
    files: ["registry/components/example/en/button/Button-demo.tsx"],
    component: React.lazy(
      () => import("@/registry/components/example/en/button/Button-demo")
    ),
  },

  "button-size-demo": {
    name: "button-size-demo",
    type: "components:example",
    files: ["registry/components/example/button/Button-size-demo.tsx"],
    component: React.lazy(
      () => import("@/registry/components/example/button/Button-size-demo")
    ),
  },
  "button-size-demo-en": {
    name: "button-size-demo-en",
    type: "components:example",
    files: ["registry/components/example/en/button/Button-size-demo.tsx"],
    component: React.lazy(
      () => import("@/registry/components/example/en/button/Button-size-demo")
    ),
  },

  "alert-demo": {
    name: "alert-demo",
    type: "components:example",
    files: ["registry/components/example/alert/Alert-demo.tsx"],
    component: React.lazy(
      () => import("@/registry/components/example/alert/Alert-demo")
    ),
  },
  "alert-demo-en": {
    name: "alert-demo-en",
    type: "components:example",
    files: ["registry/components/example/en/alert/Alert-demo.tsx"],
    component: React.lazy(
      () => import("@/registry/components/example/en/alert/Alert-demo")
    ),
  },


  "avatar-demo": {
    name: "avatar-demo",
    type: "components:example",
    files: ["registry/components/example/avatar/avatar-demo.tsx"],
    component: React.lazy(
      () => import("@/registry/components/example/avatar/avatar-demo")
    ),
  },
  "avatar-demo-en": {
    name: "avatar-demo-en",
    type: "components:example",
    files: ["registry/components/example/en/avatar/avatar-demo.tsx"],
    component: React.lazy(
      () => import("@/registry/components/example/en/avatar/avatar-demo")
    ),
  },
  "avatar-demo-alt": {
    name: "avatar-demo-alt",
    type: "components:example",
    files: ["registry/components/example/avatar/avatar-demo-alt.tsx"],
    component: React.lazy(
      () => import("@/registry/components/example/avatar/avatar-demo-alt")
    ),
  },

  "bigpost-demo": {
    name: "bigpost-demo",
    type: "components:example",
    files: ["registry/components/example/bigpost/DemoBigpost.tsx"],
    component: React.lazy(
      () => import("@/registry/components/example/bigpost/DemoBigpost")
    ),
  },
  "bigpost-demo-en": {
    name: "bigpost-demo-en",
    type: "components:example",
    files: ["registry/components/example/en/bigpost/DemoBigpost.tsx"],
    component: React.lazy(
      () => import("@/registry/components/example/en/bigpost/DemoBigpost")
    ),
  },
  "bigpost-demo-main": {
    name: "bigpost-demo-main",
    type: "components:example",
    files: ["registry/components/example/bigpost/DemoMainBigPost.tsx"],
    component: React.lazy(
      () => import("@/registry/components/example/bigpost/DemoMainBigPost")
    ),
  },
  "bigpost-demo-main-en": {
    name: "bigpost-demo-main-en",
    type: "components:example",
    files: ["registry/components/example/en/bigpost/DemoMainBigPost.tsx"],
    component: React.lazy(
      () => import("@/registry/components/example/en/bigpost/DemoMainBigPost")
    ),
  },

  "circular-progress-demo": {
    name: "circular-progress-demo",
    type: "components:example",
    files: ["registry/components/example/CircularProgress/CircularProgress-demo.tsx"],
    component: React.lazy(
      () => import("@/registry/components/example/CircularProgress/CircularProgress-demo")
    ),
  },

  "demo-data-table": {
    name: "demo-data-table",
    type: "components:example",
    files: ["registry/components/example/data-table/DemoDataTable.tsx"],
    component: React.lazy(
      () => import("@/registry/components/example/data-table/DemoDataTable")
    ),
  },
  "demo-data-table-en": {
    name: "demo-data-table-en",
    type: "components:example",
    files: ["registry/components/example/en/data-table/DemoDataTable.tsx"],
    component: React.lazy(
      () => import("@/registry/components/example/en/data-table/DemoDataTable")
    ),
  },

  "demo-dropdown-menu": {
    name: "demo-dropdown-menu",
    type: "components:example",
    files: ["registry/components/example/dropdown-menu/DemoDropdownMenu.tsx"],
    component: React.lazy(
      () => import("@/registry/components/example/dropdown-menu/DemoDropdownMenu")
    ),
  },
  "demo-dropdown-menu-en": {
    name: "demo-dropdown-menu-en",
    type: "components:example",
    files: ["registry/components/example/en/dropdown-menu/DemoDropdownMenu.tsx"],
    component: React.lazy(
      () => import("@/registry/components/example/en/dropdown-menu/DemoDropdownMenu")
    ),
  },
  "demo-dropdown-menu-positions": {
    name: "demo-dropdown-menu-positions",
    type: "components:example",
    files: ["registry/components/example/dropdown-menu/PositionsDemoDropdownMenu.tsx"],
    component: React.lazy(
      () => import("@/registry/components/example/dropdown-menu/PositionsDemoDropdownMenu")
    ),
  },
  "demo-dropdown-menu-positions-en": {
    name: "demo-dropdown-menu-positions-en",
    type: "components:example",
    files: ["registry/components/example/en/dropdown-menu/PositionsDemoDropdownMenu.tsx"],
    component: React.lazy(
      () => import("@/registry/components/example/en/dropdown-menu/PositionsDemoDropdownMenu")
    ),
  },

  "footer-demo": {
    name: "footer-demo",
    type: "components:example",
    files: ["registry/components/example/footer/DemoFooter.tsx"],
    component: React.lazy(
      () => import("@/registry/components/example/footer/DemoFooter")
    ),
  },
  "footer-demo-en": {
    name: "footer-demo-en",
    type: "components:example",
    files: ["registry/components/example/en/footer/DemoFooter.tsx"],
    component: React.lazy(
      () => import("@/registry/components/example/en/footer/DemoFooter")
    ),
  },

  "header-demo": {
    name: "header-demo",
    type: "components:example",
    files: ["registry/components/example/header/DemoHeader.tsx"],
    component: React.lazy(
      () => import("@/registry/components/example/header/DemoHeader")
    ),
  },
  "header-demo-en": {
    name: "header-demo-en",
    type: "components:example",
    files: ["registry/components/example/en/header/DemoHeader.tsx"],
    component: React.lazy(
      () => import("@/registry/components/example/en/header/DemoHeader")
    ),
  },

  "badge-rectangle": {
    name: "badge-rectangle",
    type: "components:example",
    files: ["registry/components/example/badge/Badge-rectangle.tsx"],
    component: React.lazy(
      () => import("@/registry/components/example/badge/Badge-rectangle-demo")
    ),
  },
  "badge-rectangle-en": {
    name: "badge-rectangle-en",
    type: "components:example",
    files: ["registry/components/example/en/badge/Badge-rectangle.tsx"],
    component: React.lazy(
      () => import("@/registry/components/example/en/badge/Badge-rectangle-demo")
    ),
  },

};

export const registry: Registry = {
  ...ui,
  ...example,
};
